<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="Qrcode">
        <img :src="img1" />
        <p>关注SRCC</p>
      </div>
      <section style="padding-right: 100px">
        <div class="item">
          <div class="item-name">公司总部：</div>
          <div class="item-value">上海市嘉定区春锦路253号</div>
          <div class="clearfix"></div>
        </div>
        <div class="item">
          <div class="item-name">电话：</div>
          <div class="item-value">021-56556312</div>
          <div class="clearfix"></div>
        </div>
        <div class="item">
          <div class="item-name">传真：</div>
          <div class="item-value">021-56551276</div>
          <div class="clearfix"></div>
        </div>
        <div class="item">
          <div class="item-name">邮编：</div>
          <div class="item-value">200434</div>
          <div class="clearfix"></div>
        </div>
        <div class="item">
          <div class="item-name">邮箱：</div>
          <div class="item-value">srcc@chinasrcc.com</div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </section>
      <section style="padding-right: 100px">
        <div class="item">
          <div class="item-name">成都办事处：</div>
          <div class="item-value">四川省成都市武侯区天府大道中段530号东方希望天祥广场C座38楼28号</div>
          <div class="clearfix"></div>
        </div>
        <!-- <div class="item">
                <div class="item-name">电话：</div>
                <div class='item-value'>
                    021-56556312
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">传真：</div>
                <div class='item-value'>
                    021-56551276
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">邮编：</div>
                <div class='item-value'>
                    200434
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">邮箱：</div>
                <div class='item-value'>
                    srcc@chinasrcc.com
                </div>
                <div class="clearfix"></div>
            </div> -->
        <div class="clearfix"></div>
      </section>
      <section style="padding-right: 100px">
        <div class="item">
          <div class="item-name">大连办事处：</div>
          <div class="item-value">
            辽宁省大连市中山区长江路280号大连中心裕景5号楼ST2大厦
          </div>
          <div class="clearfix"></div>
        </div>
        <!-- <div class="item">
                <div class="item-name">电话：</div>
                <div class='item-value'>
                    021-56556312
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">传真：</div>
                <div class='item-value'>
                    021-56551276
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">邮编：</div>
                <div class='item-value'>
                    200434
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">邮箱：</div>
                <div class='item-value'>
                    srcc@chinasrcc.com
                </div>
                <div class="clearfix"></div>
            </div> -->
        <div class="clearfix"></div>
      </section>
      <section>
        <div class="item-name">合作交流：</div>
        <div class="item-value">
          <ul>
            <li v-for="(item, index) in customers" :key="index">
              <a :href="item.webAddress"
                ><img :src="item.logoUrl" alt="" style="height: 30px"
              /></a>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </section>
      <section>
        <div class="item-name">友情链接：</div>
        <div class="item-value">
          <ul>
            <li><a href="https://www.samr.gov.cn/">国家市场监督管理总局</a></li>
            <li>
              <a href="http://www.cnca.gov.cn/"
                >中国国家认证认可监督管理委员会</a
              >
            </li>
            <li>
              <a href="https://www.cnas.org.cn/">中国合格评定国家认可委员会</a>
            </li>
            <li><a href="http://www.ccaa.org.cn/">中国认证认可协会</a></li>
            <li><a href="https://www.tongji.edu.cn/">同济大学</a></li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </section>
      <section class="copyright">
        <span>Copyright © 2022 版权所有：上海轨道交通检测认证（集团）有限公司</span>
        <span>沪ICP备16013901号-2</span>
        <span>
          <img :src="img2" />
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402008424"
          >
            <span>沪公网安备 31011402008424号</span></a
          ></span
        >
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBar",
  data() {
    return {
      img1: process.env.BASE_URL + "img/qrcode.png",
      img2: process.env.BASE_URL + "img/Bitmap.png",
      customers: [
        {
          webAddress: "http://www.bjsubway.com/",
          logoUrl: process.env.BASE_URL + "img/metro/beijing.jpg",
        },
        {
          webAddress: "https://www.chengdurail.com/",
          logoUrl: process.env.BASE_URL + "img/metro/chengdu.jpg",
        },
        {
          webAddress: "https://www.cqmetro.cn/",
          logoUrl: process.env.BASE_URL + "img/metro/chongqing.jpg",
        },
        {
          webAddress: "http://www.shmetro.com/",
          logoUrl: process.env.BASE_URL + "img/metro/shanghai.jpg",
        },
        {
          webAddress: "http://www.hzmetro.com/",
          logoUrl: process.env.BASE_URL + "img/metro/hangzhou.jpg",
        },
        {
          webAddress: "https://www.gzmtr.com/",
          logoUrl: process.env.BASE_URL + "img/metro/guangzhou.jpg",
        },
      ],
    };
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Microsoft YaHei";
  font-size: 12px;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clearfix {
  clear: both;
}
ul > li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000000;
}
img {
  max-width: 100%;
}
.content {
  padding: 30px 80px;
}
a {
  color: inherit;
}

a:link {
  color: inherit;
}

a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
}

a:active {
  color: inherit;
}

.footer {
  background: rgb(34, 34, 54);
  padding: 30px 0px 16px;
  position: relative;
  width: 100%;
  min-width: 1260px;
  z-index: 129;
}

.footer-inner {
  width: 1260px;
  margin: 0 auto;
  position: relative;
}

.item {
  float: left;
  padding-right: 15px;
  line-height: 30px;
}

.item-name {
  color: #ffffff;
  letter-spacing: 1px;
  float: left;
  line-height: 30px;
}

.item-value {
  color: #c4c4c4;
  float: left;
  line-height: 30px;
}

.item-value li {
  padding-right: 10px;
}

ul li {
  list-style: none;
  display: inline-block;
}

.copyright {
  margin-top: 15px;
  margin-bottom: 50px;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  text-align: center;
  color: #aaa;
}

.copyright img {
  position: relative;
  top: 5px;
  left: -8px;
}

.copyright>span{
    margin: 0 30px;
}

.Qrcode {
  position: absolute;
  right: 0px;
  color: #c4c4c4;
  width: 140px;
  text-align: center;
}
</style>