<template>
  <div class="em-box">
    <div class="p-box"></div>
    <div class="navwrap" :style="{height:navHeight}">
      <div class="header-inner">
        <a href="/"><img :src="logoUrl" class="logo" :style="{width:logoWidth,maxHeight:navHeight}"/></a>
        <div class="clearfix"></div>
      </div>
      <div class="navBox">
        <ul class="navUl">
          <li
            class="navLi"
            v-for="ele in nav_content"
            @mouseenter="me(ele.name)"
            @mouseleave="ml"
            :key="ele.name"
          >
            <span class="navSpan" @click="toUrl(ele.url)">{{ ele.name }}</span>
            <ul
              class="drag"
              v-show="nav.state == ele.name && ele.children.length !== 0"
            >
              <li
                class="dragLi"
                v-for="item in ele.children"
                @mouseenter="subme(item.name)"
                @mouseleave="subml"
                :key="item.name"
              >
                <a class="dragLi-word" :href="item.url">
                  {{ item.name }}
                </a>
              </li>
              <div class="dragAngel"></div>
            </ul>
          </li>
        </ul>
      </div>
      <div class="navBox">
        <div class="search-lang">
          <span class="search"><Icon type="ios-search" :size="30" /><div class="search-input">
            <input type="text"/><span @click="toUrl('/services_list/all')"><Icon type="ios-search" :size="24" /></span>
          </div></span>
          <!-- <span class="lang">
            <span v-if="lang === '中文'">EN</span>
            <span v-else>中文</span>
          </span> -->
        </div>
        <div class="hotline">
          <div class="call-wrapper"><Icon type="md-call" :size="30" /></div>
          <div>
            <p>咨询热线</p>
            <a href="tel:021-56556312">021-56556312</a>
            <div class="worktime">
              <p>工作时间：周一~周五</p>
              <p>（节假日除外）9:00~17:00</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "navBar",
  components: {},
  data() {
    return {
      imgUrl: process.env.BASE_URL + "img/login_bg.jpg",
      logoUrl: process.env.BASE_URL + "img/logo.png",
      nav: {
        state: "",
        substate: "",
      },
      lang: "中文",
      logined: false,
      loginAuth: [],
      navHeight:"138px",
      logoWidth:"100%",
    };
  },
  computed: {
    nav_content: function () {
      return [
        // {
        //   name: "首页",
        //   url: "/",
        //   children: [],
        // },
        {
          name: "关于我们",
          url: undefined,
          children: [
            {
              name: "公司简介",
              url: "/introduce",
              children: [],
            },
            {
              name: "资质荣誉",
              url: "/qualification_reputation/all",
              children: [],
            },
            { name: "发展历程", url: "/development_history", children: [] },
            // { name: "典型案例", url: "/", children: [] },
          ],
        },
        {
          name: "我们的服务",
          url: "",
          children: [
            { name: "检测试验", url: "/services_list/test", children: [] },
            {
              name: "检验评估",
              url: "/services_list/evaluate",
              children: [],
            },
            {
              name: "认证服务",
              url: "/services_list/certification",
              children: [],
            },
            {
              name: "科技创新",
              url: "/services_list/innovation",
              children: [],
            },
          ],
        },
        // {
        //   name: "您的行业",
        //   url: "",
        //   children: [
        //     { name: "轨道交通", url: "/industries_list/railway", children: [] },
        //     {
        //       name: "汽车",
        //       url: "/industries_list/vehicle",
        //       children: [],
        //     },
        //   ],
        // },
        {
          name: "新闻中心",
          url: undefined,
          children: [
            {
              name: "公司动态",
              url: "/news_list",
              children: [],
            },
            // { name: "行业新闻", url: "/network/center", children: [] },
          ],
        },

        // {
        //   name: "检测试验",
        //   url: undefined,
        //   children: [
        //     { name: "材料及部件检测", url: "/services_list/test", children: [] },
        //     { name: "轨道交通测试", url: "/services_list/2", children: [] },
        //   ],
        // },

        // {
        //   name: "检验评估",
        //   url: "",
        //   children: [
        //     { name: "运营安全评估", url: "/services_list/safe", children: [] },
        //     {
        //       name: "系统RAMS评估",
        //       url: "/services_list/signal",
        //       children: [],
        //     },
        //     {
        //       name: "服役安全评估",
        //       url: "/services_list/construction",
        //       children: [],
        //     },
        //   ],
        // },
        // {
        //   name: "认证服务",
        //   url: "",
        //   children: [
        //     { name: "产品认证", url: "/services_list/safe", children: [] },
        //     { name: "服务认证", url: "/services_list/signal", children: [] },
        //     {
        //       name: "体系认证",
        //       url: "/services_list/construction",
        //       children: [],
        //     },
        //   ],
        // },
        // {
        //   name: "科技创新",
        //   url: "",
        //   children: [
        //     { name: "科研项目", url: "/services_list/safe", children: [] },
        //     {
        //       name: "科技服务平台",
        //       url: "/services_list/signal",
        //       children: [],
        //     },
        //   ],
        // },
        {
          name: "文件中心",
          url: "/file_list/all",
          children: [
            { name: "公开性文件", url: "/file_list/public", children: [] },
            {
              name: "测试申请表",
              url: "/file_list/test_application_form",
              children: [],
            },
            { name: "服务指南", url: "/file_list/service_guide", children: [] },
            { name: "资质证书", url: "/file_list/certificate", children: [] },
            { name: "公司宣传册", url: "/file_list/brochure", children: [] },
            { name: "其他", url: "/file_list/other", children: [] },
          ],
        },
        {
          name: "联系我们",
          url: "",
          children: [
            { name: "联系方式", url: "/contact", children: [] },
            { name: "菁英招募", url: "/recruiting_list", children: [] },
          ],
        },
      ];
    },
    
  },
  mounted(){
    window.addEventListener("scroll",this.scrollHandler);
  },
  destroyed(){
    window.removeEventListener("scroll",this.scrollHandler);
  },
  props: {},
  methods: {
    scrollHandler:function(){
      let currentScrollHeight = document.documentElement.scrollTop||document.body.scrollTop;
      if(currentScrollHeight>=300){
        this.navHeight = "70px";
        this.logoWidth = "70%";
      }else{
        this.navHeight = "138px";
        this.logoWidth = "100%";
      }
    },
    me: function (name) {
      this.nav.state = name;
    },
    ml: function () {
      this.nav.state = "";
    },
    subme: function (name) {
      this.nav.substate = name;
    },
    subml: function () {
      this.nav.substate = "";
    },
    toUrl: function (url) {
      if (url) {
        location.href = url;
      }
    },
    LogOut: function () {
      localStorage.clear();
      this.logined = false;
      location.href = "/login";
    },
    goSearch: function () {
      location.href = "/search/search_result";
    },
  },
};
</script>

<style scoped>
a {
  color: inherit;
}

a:hover {
  color: inherit;
}

a:active {
  color: inherit;
}

header {
  width: 100%;
  background-color: #fff;
}

.p-box {
  height: 117px;
}

.header-inner {
  padding: 10px 20px;
  width:27%;
}

.logo {
  width:100%;
  max-height: 138px;
  transition: all .2s linear;
}

.navwrap {
  height: 138px;
  border-bottom: 1px solid #eee;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  top: 0;
  z-index: 10000;
  background: #fff;
  transition: all .2s linear;
}

.navBox {
  background: white;
  display: flex;
  align-items: center;
}

.navUl {
  float: left;
  width: auto;
  height: 100%;
}

.navLi {
  width: auto;
  
  font-size: 16px;
  color: rgb(51, 51, 51);
  float: left;
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-right: 38px;
  list-style: none;
  color: rgb(51, 51, 51);
}



.navSpan {
  padding: 10px 0;
}

.navSpan:hover {
  color: #b70005;
  border-bottom: 2px solid #b70005;
}

.drag {
  width: 150px;
  height: auto;
  position: absolute;
  z-index: 9999;
  top: 32px;
  left: -43px;
  background: #f3f7fa;
}

.sub-drag {
  border-top: none;
  top: 2px;
  left: 150px;
}

.dragLi {
  width: 150px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  text-align: center;
  list-style: none;
  position: relative;
  padding: 0 10px;
  border-top: 1px solid #fff;
}


.dragLi-word {
  width: 100%;
  float: left;
  text-align: center;
}

.dragLi:hover {
  background: #00508e;
}

.dragLi:hover .dragLi-word {
  font-weight: 600;
  color: #fff;
  transition: color 0.1s ease;
}

.dragAngel {
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  font-size: 0;
  top: -13px;
  left: 66px;
  line-height: 0px;
  border-width: 0px 10px 10px;
  border-style: solid solid solid solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #b70005;
}
.med-wrapper-box {
  margin: 0 auto;
  width: 100%;
  min-width: 1250px;
  margin-bottom: 60px;
  background-color: #fff;
}

.m-content-wrapper {
  background: #fff;
  width: 1260px;
  margin: 0 auto;
}
.navSearchBox {
  /* width: auto; */
  height: 46px;
  width: 45%;
  float: right;
  margin-top: 27px;
}
.navSearchIcon {
  width: 46px;
  height: 40px;
  background: #b70005;
  /* background: url(../images/1-3.jpg) no-repeat 50% 50%; */
  /* background-size: contain; */
  float: right;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.navSearch {
  width: 250px;
  height: 40px;
  border: none;
  outline: none;
  border: 1px solid #b70005;
  float: right;
  padding: 2px 0 2px 10px;
  font-size: 16px;
  line-height: 40px;
}
.placeholder {
  color: #aaa;
}
.navSearchIconIcon {
  width: 20px;
  height: 20px;
  background: url(../assets/search.png) no-repeat 50% 50%;
  /* background-size: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bread-wrapper {
  width: 1260px;
  margin: 20px auto;
}

.lang {
  font-size: 20px;
  border-left: 1px solid #eee;
  padding-left: 10px;
  cursor: pointer;
}

.search {
  position: relative;
  top: 3px;
  padding-right: 10px;
  cursor: pointer;
}

.hotline {
  display: flex;
  align-items: center;
  position: relative;
  top: 3px;
  padding-right: 30px;
  font-size: 19px;
  color: #00508e;
}

.hotline p {
  font-size: 14px;
  color: #666;
}

.call-wrapper {
  padding-right: 15px;
  padding-left: 30px;
}

.search:hover .search-input{
  display:flex;
}

.search-input{
  position: absolute;
  top:18px;
  right:-210px;
  display: none;
}

.search-input input{
  outline: none;
  padding: 10px 20px;
  border:1px solid #eee;
  font-size: 20px;
  width:300px;
  background: #fff;
}

.search-input span{
  display: inline-block;
  line-height: 58px;
  height:52px;
  background: #00508e;
  width:100px;
  color:#fff;
  text-align: center;
  position: relative;
}

.worktime{
  position: absolute;
  display: none;
  right:40px;
  text-align: center;
}

.worktime p{
  font-size: 12px;
  color:#aaa;
}

.hotline:hover .worktime{
  display: block;
}

</style>