import { render, staticRenderFns } from "./GuestFrame.vue?vue&type=template&id=54284311&scoped=true&"
import script from "./GuestFrame.vue?vue&type=script&lang=js&"
export * from "./GuestFrame.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54284311",
  null
  
)

export default component.exports