<template>
  <div class="layout">
    <nav-bar></nav-bar>
    <router-view></router-view>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import navBar from "./NavBar.vue"
import footerBar from "./FooterBar.vue";


export default {
  name: "mainFrame",
  components: {
    navBar,
    footerBar,
  },
  data() {
    return {
      
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>